<template>
  <div>
    <Header />
    <br /><br /><br /><br /><br /><br /><br />
    <div class="container fluid">
      <div class="row" style="margin-top: 50px">
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/production_equipement/board_handling_system/Flip_Turning/inverted_board_flipper">
          <div class="card" style="width:260px;height:180px;">
          
            <img
              style="max-height:80%; max-width:80%;"
              class="card-img-top img-fluid"
              src="../../../../../assets/images/product/main/production_equipement/Bord_handling_system/Flip_Turning/INVERTER(BOARD FLIPPER).jpg"
              alt="Card image cap"
            />
            
            <div class="card-body">
              <h4 style="text-align: center" class="card-title">
               INVERTER(BOARD FLIPPER)
              </h4>
            </div>
          </div>
          </router-link>
        </div>
        <!-- end col -->
       
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link
           to="/production_equipement/board_handling_system/Flip_Turning/90_turning_conveyor">
          <div class="card" style="width:260px;height:180px;">
            <img
            style="max-height:80%; max-width:80%;"
              class="card-img-top img-fluid"
              src="../../../../../assets/images/product/main/production_equipement/Bord_handling_system/Flip_Turning/90˚ TURNING CONVEYOR.jpg"
              alt="Card image cap"
            />
            <div class="card-body">
              <h4 style="text-align: center" class="card-title">
                90˚ TURNING CONVEYOR
              </h4>
            </div>
          </div>
          </router-link>
        </div>
        <!-- end col -->
      </div>
    

    
    </div>
    <Footer />
  </div>
</template>
<script>
import Header from "../../../../../components/Header.vue";

import Footer from "../../../../../components/Footer.vue";

/**
 * Dashboard component
 */
export default {
  components: {
    Header,

    Footer,
  },
};
</script>